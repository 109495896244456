import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BlogBink, { BlogBinkPosts } from '@ubo/blog-bink'

// Layout
import Grid from 'components/flex/Posts/Layout/Grid'

interface BlogProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const Latest: React.FC<BlogProps> = ({ fields }) => {
  const { allWpPost } =
    useStaticQuery<GatsbyTypes.latestPostsQueryQuery>(graphql`
      query latestPostsQuery {
        allWpPost {
          edges {
            node {
              ...generalPostFragment
            }
          }
        }
      }
    `)

  const posts: unknown = allWpPost.edges

  return (
    <section className="mb-lg-5">
      <BlogBink posts={posts as BlogBinkPosts} id="latest-posts" limit={3}>
        <div className="py-5">
          <Grid fields={fields} slider />
        </div>
      </BlogBink>
    </section>
  )
}

export default Latest
